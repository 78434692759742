import react, { useEffect, useState } from 'react';
import './Rsvp.scss';
import { SaveResponseRequest } from '../../models/SaveResponseRequest';
import { getResponse, saveResponse } from '../../services/responseService';
import Spinner from '../shared/Spinner/Spinner';
import { GuestResponseResponse } from '../../models/GuestResponseResponse';
import { GuestResponse } from '../../models/GuestResponse';
import { getGuest } from '../../services/session';

const Rsvp = () => {

    const [guest, setGuest] = useState<GuestResponse | undefined>(undefined);
    const [response, setResponse] = useState<GuestResponseResponse | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [mobileNumber, setMobileNumber] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [lastClicked, setLastClickedButton] = useState<string>('');

    useEffect(() => {
        setInitialResponse();
    }, []);

    useEffect(() => {
        if (!isValid && mobileNumber?.length > 0) {
            setErrorMessage('Mobile number is not valid.');
        } else {
            setErrorMessage('');
        }
    }, [mobileNumber, isValid])

    const setInitialResponse = () => {
        const savedGuest = getGuest();

        setGuest(savedGuest ?? undefined);

        const fetchData = async () => {
            const response = await getResponse();
            const data = response.data;
            setResponse(data);
        };
        
        fetchData();
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value;
        const regex = /^09\d{9}$/; // Mobile number format validation
    
        setMobileNumber(input);
        setIsValid(regex.test(input)); // Validate input using regex

        if (!input?.length) {
            setIsValid(true);
        }
    };

    const saveRsvpResponse = async (response: boolean, lastClicked: string)  => {
        setIsLoading(true);
        setLastClickedButton(lastClicked);

        const timer = setTimeout(async () => {

            const request: SaveResponseRequest = {
                isGoing: response,
                mobileNumber: response ? mobileNumber : ''
            };
    
            await saveResponse(request); 
    
            setIsLoading(false);

            setInitialResponse();
           
        }, 1000); 

        return () => clearTimeout(timer);
    };

    const changeResponse = () => {
        let responseCopy: GuestResponseResponse = JSON.parse(JSON.stringify(response));
        responseCopy.hasAlreadyVoted = false;
        setResponse(responseCopy);
    };

    const getSeatPhrase = (guestCount: number) => {
        let result = <></>;

        if (guestCount === 1) {
            result = <span className="content"> A SEAT FOR YOU:</span>;
        }

        if (guestCount > 1) {
            result = <span className="content">&nbsp;<span className="nav-font">{guestCount}</span>&nbsp;SEATS FOR  YOU:</span>
        }

        return result;
    };

    const getGuests = (preferredName: string): string[] => {
        let result: string[] = [];

        result = preferredName.split(',');

        return result;
    };

    return <>
        <div className="rsvp-container pl-1 pr-1">
            <div className="rsvp-headings-container">
                <div className="">
                    <span className="content">ANGELICA & KYLE</span>
                </div>
                <div className="mt-1">
                    <span className="header-title">RSVP</span> 
                </div>
            </div>

            <div className="rsvp-box mt-1">
                {
                    response && !response.hasAlreadyVoted &&
                    <>
                        <div className="pl-1">
                            <span className="content">
                                WE HAVE RESERVED {getSeatPhrase(guest?.guestCount ?? 1)} <br/>
                            </span>
                        </div>
                        <div className="mt-1 pl-1 pr-1">
                            {
                                getGuests(guest?.preferredName ?? '')?.map((n, i) => {
                                    return <div className="seat-item" key={i}> <span className="section-title bb">{n}</span></div>
                                })
                            }
                        </div>
                        <div className="mt-1 pl-1 pr-1">
                            <span className="content">
                                PLEASE LET US KNOW IF YOU'LL BE ATTENDING, AND FEEL FREE TO UPDATE YOUR RESPONSE UNTIL <span className="cta"><strong>NOVEMBER 16, 2024</strong></span>. 
                            </span>
                        </div>
                        
                        <div className="mt-1 pl-1  pr-1">
                            <span className="content">
                                WE ALSO KINDLY ASK FOR YOUR MOBILE NUMBER SO WE CAN KEEP YOU INFORMED OF ANY IMPORTANT UPDATES ON THE DAY OF OUR EVENT.
                            </span>
                        </div>
                        
                        <div className="mt-1">
                            <div className="pl-1 pr-1 mt-2">
                                <input 
                                    type="text"
                                    id="mobile-number"
                                    value={mobileNumber}
                                    onChange={handleInputChange}
                                    placeholder="09123456789"
                                    maxLength={11}
                                    autoComplete="off"/>
                            </div>
                        </div>
                        <div className="mt-1 pl-1 pr-1">
                            <span className="content">{errorMessage}</span>
                        </div>
                        <div className="button-container">
                            <div className="align-left fill-width pl-1 pr-1 mt-1 ">
                                <button onClick={async () => { await saveRsvpResponse(true, 'l') }} disabled={!isValid || (isLoading && lastClicked === 'l')} type="button" className="btn-yes">
                                    Wouldn't miss it!
                                </button>
                            </div>
                            <div className="align-right fill-width pl-1 pr-1 mt-1">
                                <button onClick={async () => { await saveRsvpResponse(false, 'r') }} disabled={(isLoading && lastClicked === 'r')} type="button" className="btn-no">
                                    Sorry to miss it!
                                </button>
                            </div>
                        </div>        
                    </>
                }
                {   
                    response && response.hasAlreadyVoted &&
                    <>
                        <div className='mt-1  pl-1 pr-1'>
                            <span className="content bb">
                            {
                                response?.yourResponse ? 
                                    "YOU HAVE ALREADY CONFIRMED YOUR ATTENDANCE. WE'RE EXCITED TO CELEBRATE WITH YOU!"
                                     : "YOU HAVE ALREADY INDICATED THAT YOU WON'T BE ABLE TO JOIN US. WE'LL MISS YOUR PRESENCE!"
                            } 
                            </span> <br/><br/>
                            <span className="content">
                                FEEL FREE TO UPDATE YOUR RESPONSE UNTIL <span className="cta"><strong>NOVEMBER 16, 2024</strong></span>.
                            </span> 
                        </div>

                        <div className="mt-1 p-1">
                            <button onClick={() => { changeResponse(); }} className="btn-yes">CHANGE MY RESPONSE</button>
                        </div>
                    </>
                }
                
            </div>
        </div>
    
    </>
};

export default Rsvp;