import "./ScheduleBox.scss";

export interface ScheduleBoxProps {
  ceremonyTime: string,
  month: string,
  dayName: string,
  dayNumber: string,
  year: string,
  place: string,
  receptionPhrase: string,
}

const ScheduleBox: React.FC<ScheduleBoxProps> = ({dayName = '', dayNumber = '', month = '', place = '', receptionPhrase = '', ceremonyTime = '', year = ''}) => {
  return (
    <>
      <div className="schedule-container mt-2 pl-1 pr-1">
        <div className="schedule-box">
          <div className="">
            <span className="content">{ceremonyTime}</span>
          </div>

          <div className="date-section mt-1">
            <div className="date-item bl">
              <span className="section-title">{month}</span>
            </div>
            <div className="date-item bl br">
              <span className="section-title">{dayName}</span> <br/>
              <span className="section-title">{dayNumber}</span>
            </div>
            <div className="date-item br">
              <span className="section-title">{year}</span>
            </div>
          </div>

          <div className="mt-1">
            <span className="content">
              {place}
            </span>
          </div>

          <div className="mt-1">
            <span className="content">
              {receptionPhrase}
            </span>
          </div>

          <div></div>
        </div>
      </div>
    </>
  );
};

export default ScheduleBox;
