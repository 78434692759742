import { useEffect, useState } from "react";
import { signalRConnection } from "../../../services/signalR";
import { getGuest, getToken } from "../../../services/session";
import { HubConnection } from "../../../models/HubConnection";
import GuestIcon from "../GuestIcon/GuestIcon";
import './Visitors.scss';
import { GuestResponse } from "../../../models/GuestResponse";

const MAX_VISIBLE_GUESTS = 2; // Limit of visible guest icons

const Visitors = () => {
    const [connection, setConnection] = useState<any>(undefined);
    const [guests, setGuests] = useState<HubConnection[]>([]);
    const [guest, setGuest] = useState<GuestResponse>();
    const [showHiddenGuests, setShowHiddenGuests] = useState(false); // Toggle hidden guests visibility

    useEffect(() => {
        connection && startHubConnection();
        return () => {
            connection && stopHubConnection();
        };
    }, [connection]);

    useEffect(() => {
        const guest = getGuest();
        if (guest) {
            createHubConnection(guest.id, guest.preferredName);
            setGuest(guest);
        }
        
    }, []);

    const createHubConnection = async (userId: string, guestPreferredName: string) => {
        const { getConnection } = signalRConnection;
        const token = getToken();
        setConnection(getConnection(token, userId, guestPreferredName));
    };

    const startHubConnection = () => {
        const { startConnection, attachEventHandlerToConnection } = signalRConnection;
        startConnection(connection);
        attachEventHandlerToConnection(
            connection,
            'BroadcastUserList',
            (visitorsStr: string) => {
               try {
                const visitors: HubConnection[]  = JSON.parse(visitorsStr);
                setGuests(visitors);
               } catch(ex) {

               }
            }
        );
    }

    const stopHubConnection = () => {
        const { stopConnectiton } = signalRConnection;
        if (connection) {
            stopConnectiton(connection);
        }
    };

    const sortAndProject = (paramVisitors: HubConnection[]) => {
        if (!paramVisitors?.length) {
            return [];
        }

        const sorted = [...paramVisitors].sort((a, b) => a.PreferredName.localeCompare(b.PreferredName));

        const named: HubConnection[] = [];

        sorted.forEach((v: HubConnection) => {
            const temp: HubConnection = JSON.parse(JSON.stringify(v));
            const indexOf = named
                    ?.map((n) => n.PreferredName)
                    ?.indexOf(temp?.PreferredName);

            if (indexOf === -1) {
                named.push(temp);
            }
        });

        return named;
    };

    const visibleGuests = sortAndProject(guests).slice(0, MAX_VISIBLE_GUESTS);
    const hiddenGuests = sortAndProject(guests).slice(MAX_VISIBLE_GUESTS);
    const hiddenGuestsCount = sortAndProject(guests).length - MAX_VISIBLE_GUESTS;

    return (
        <div className="visitor-container">
            {
                visibleGuests.map((g: HubConnection) => (
                    <GuestIcon currentUserName={guest?.preferredName ?? ''} key={g.ConnectionId} text={g?.PreferredName} size={24} />
                ))
            }

            {hiddenGuestsCount > 0 && (
                <div
                    className="more-guests"
                    onMouseEnter={() => setShowHiddenGuests(true)}
                    onMouseLeave={() => setShowHiddenGuests(false)}
                >
                    +{hiddenGuestsCount}

                    {showHiddenGuests && (
                        <div className="hidden-guests-container">
                            {
                                hiddenGuests.map((g: HubConnection) => (
                                    <GuestIcon currentUserName={guest?.preferredName ?? ''} key={g.ConnectionId} text={g?.PreferredName} size={24} />
                                ))
                            }
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Visitors;
