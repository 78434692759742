import './WeddingLocation.scss';
import adrianos from './../../../assets/images/adrianos.jpg';
import adrianosqr from './../../../assets/images/location_qr.svg';

export interface WeddingLocatitonProps {
    header: string,
    location: string,
    link: string
};

const WeddingLocation: React.FC<WeddingLocatitonProps> = ({header, link, location }) => {

    return <>
        <div className="wedding-location-container pl-1 pr-1 mt-2">
            <div>
                <span className="header-title">
                    {header}
                </span>
            </div>
            <div className="mt-1 responsive-image-container">
                <img className="responsive-image" src={adrianos} alt="adrianos events place" />
                <div className="image-overlay">
                    <span className="content pl-1 pr-1">{location}</span>
                </div>
            </div>

            <div className="qr-code">
                <a href={link} target="_blank" rel="noopener noreferrer">
                    <img src={adrianosqr}  alt="" />
                </a>
            </div>

        </div>
    </>
};

export default WeddingLocation;