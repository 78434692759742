import './EntourageHeadings.scss';

const EntourageHeadings = () => {
    return <>
        <div className="entourage-headings-container">
            <div className="">
                <span className="content">ANGELICA & KYLE</span>
            </div>
            <div className="mt-1">
                <span className="header-title">ENTOURAGE</span> 
            </div>
        </div>
    </>;
};

export default EntourageHeadings;