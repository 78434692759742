import React, { useState } from 'react';

import './DismissableMessage.scss';

interface DismissableProps {
    guestName: string;
    message: string;
}

const DismissableMessage: React.FC<DismissableProps> = ({ guestName, message }) => {
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const handleDismiss = () => {
    setShowMessage(!showMessage);
  };

  return <>
    <div className="message-container clickable" onClick={handleDismiss} >
        {
            showMessage ? 
            <>
                <span className="content">
                    {message}
                </span>
            </> :
            <>
              <span className="section-title clickable"> ✉ </span>
            </>
        }
    </div>
  </>
  ;
};

export default DismissableMessage;
