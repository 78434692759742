import react, { useEffect, useState } from 'react';
import EntourageHeadings from './components/EntourageHeadings';
import EntourageContent, { EntourageContentProps } from './components/EntourageContent';

import { WeddingData } from '../../models/WeddingInfoResponse';
import { getWeddingInfo } from '../../services/session';

const Entourage = () => {

    const [weddingInfo, setWeddingInfo] = useState<WeddingData>();

    useEffect(() => {

        const weddingInfo = getWeddingInfo();

        if (weddingInfo) {
            setWeddingInfo(weddingInfo);
        }
    }, []);

    return <>
        <EntourageHeadings/>
        {
            weddingInfo?.entourage && (
                <EntourageContent entourage={weddingInfo?.entourage}/>
            )
        }
    </>
};

export default Entourage;