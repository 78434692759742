import { GuestResponse } from "../models/GuestResponse";
import { WeddingData } from "../models/WeddingInfoResponse";

const storage = sessionStorage;

const KEY_GUEST = 'guestInfo';
const KEY_TOKEN = 'guestToken';
const KEY_WEDDING_INFO = 'weddingInfo';

const getFromStorage = (key: string): string | null => {
    const value = storage.getItem(key);
    return value;
};

const saveToStorage = (key: string, value: any): void => {
    storage.setItem(key, value);
};

const removeFromStorage = (key: string): void => {
    storage.removeItem(key);
};

export const isGuestLoggedIn = () => {
    let result = false;

    try {
        const value = JSON.parse(getFromStorage(KEY_GUEST) ?? '');
        result = !value?.length;
    } catch(ex) {
        result = false;
    }

    return result;
};

export const saveToken = (bearerToken: string) => {
    try {
        saveToStorage(KEY_TOKEN, bearerToken);
    } catch(ex) {
        console.error("Error saving token.");
    }
};

export const getToken = (): string => {
    let result = '';
    try {
        result = getFromStorage(KEY_TOKEN) ?? '';
    } catch(ex) {
        console.error("Error getting token.");
    }

    return result;
};

export const getGuest = (): GuestResponse | null => {
    let result: GuestResponse | null = null;

    try {
        result = JSON.parse(getFromStorage(KEY_GUEST) ?? '');
    } catch(ex) {
        console.error("Error getting  guest info.");
    }

    return result;
};

export const saveGuest = (guest: GuestResponse) => {
    try {
        let guestStr = JSON.stringify(guest);
        saveToStorage(KEY_GUEST, guestStr);
    } catch(ex) {
        console.error("Error saving guest.");
    }
};

export const saveWeddingInfo = (weddingInfo: WeddingData) => {
    try {
        let infoStr = JSON.stringify(weddingInfo);
        saveToStorage(KEY_WEDDING_INFO, infoStr);
    } catch(ex) {
        console.error("Error saving wedding info.");
    }
};

export const getWeddingInfo = (): WeddingData | null => {
    let result: WeddingData | null = null;

    try {
        result = JSON.parse(getFromStorage(KEY_WEDDING_INFO) ?? '');
    } catch(ex) {
        console.error("Error retrieving wedding info.");
    }

    return result;
};


export const destroySession = () => {
    removeFromStorage(KEY_GUEST);
    removeFromStorage(KEY_TOKEN);
    removeFromStorage(KEY_WEDDING_INFO);
};