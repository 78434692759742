import { HttpTransportType, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';

export const signalRConnection = {
    getConnection:  (token: string, guestId: string, guestPreferredName: string) => {
        return new HubConnectionBuilder()
            .withUrl(
                `${process.env.REACT_APP_API_URL}/wedinithub?guest_id=${encodeURIComponent(guestId)}&guest_preferred_name=${encodeURIComponent(guestPreferredName)}`, 
                {
                    // skipNegotiation: true,
                    // transport: 1,
                    accessTokenFactory: () => token,
                    withCredentials: true,
                    // Pass claims (e.g., guest_id, guest_preferred_name) as query parameters
                    transport: HttpTransportType.WebSockets,
                    headers: {
                        'guest_id': guestId,
                        'guest_preferred_name': guestPreferredName
                    }
                },
            )
            .withAutomaticReconnect()
            .build();
    },
    startConnection: async (connection: HubConnection, callback?: () => void) => {
        try {
            await connection.start();
        } catch(err) {
            console.error('Connection failed', err);
        } finally {
            if (callback) {
                callback();
            }
        }
    },
    stopConnectiton: async (connection: HubConnection) => {
        try {
            await connection.stop();
        } catch(err) {
            console.error('Disconnection failed', err)
        }
    },
    attachEventHandlerToConnection: (
        connection: HubConnection, 
        eventName: string,
        callback: (...args: any[]) => void
    ) => {
        connection.on(eventName, callback);
    }
}