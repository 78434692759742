import api from './api';
import { AxiosResponse } from 'axios';
import { SaveResponseRequest } from '../models/SaveResponseRequest';
import { GuestResponseResponse } from '../models/GuestResponseResponse';

const controller = "response";

const saveResponseUrl = `${controller}`;

export const saveResponse = (req: SaveResponseRequest): Promise<AxiosResponse<string>> => {
    return api.post<string>(saveResponseUrl, req);
};

export const getResponse = (): Promise<AxiosResponse<GuestResponseResponse>> => {
    return api.get<GuestResponseResponse>(saveResponseUrl);
};

