import './App.css';
import './assets/fonts/fonts.css';
import './pages/shared/styles.scss';

import Home from './pages/home/Home';

import { BrowserRouter as Router, Routes, Route, useParams } from 'react-router-dom';

import { AuthProvider } from './services/authContext';
import Entourage from './pages/entourage/Entourage';
import Rsvp from './pages/rsvp/Rsvp';
import GiftRegistry from './pages/gift-registry/GiftRegistry';
import People from './pages/people/People';
import PrivateRoute from './services/PrivateRoute';
import Login from './pages/shared/Login/Login';

const App: React.FC = () => {

  return (
    <Router>
        <Routes>
          <Route path="/*" element={<LayoutContainer />}/>
        </Routes>
    </Router>
  );
}

const LayoutContainer: React.FC = () => {

  return (
    <>
      <div>
        <AuthProvider>
            <Routes>
              <Route element={<PrivateRoute />}>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/entourage" element={<Entourage />} />
                <Route path="/rsvp" element={<Rsvp />} />
                <Route path="/gift-registry" element={<GiftRegistry />} />
                <Route path="/people" element={<People />} />
              </Route>
              <Route path="/login*" element={<Login />}/>
              <Route path="*" element={<Home />} />  
            </Routes>
        </AuthProvider>
      </div>
    </>
  )
};

export default App;
