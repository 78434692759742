import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { getGuest, isGuestLoggedIn, destroySession } from './session';
import { GuestResponse } from '../models/GuestResponse';

import Login from '../pages/shared/Login/Login';
import Header from '../pages/shared/Header/Header';
import Footer from '../pages/shared/Footer/Footer';
import DismissableMessage from '../pages/shared/DismissableMessage/DismissableMessage';

interface AuthContextType {
    isAuthenticated: boolean;
    guest: GuestResponse | undefined;
    login: (() => void) | null | undefined;
    logout: (() => void) | null | undefined;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const navigate = useNavigate();

    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [guest, setGuest] = useState<GuestResponse>(); 

    const login = () => setIsAuthenticated(true);
    const logout = () => setIsAuthenticated(false);

    useEffect(() => {
      if (isGuestLoggedIn()) {
        handleLogin(false, () => {
            setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    }, []);

    const handleLogin = (shouldRedirect: boolean = false, callback?: () => void) => {
        const savedGuest = getGuest();
        if (savedGuest) {
            setGuest(savedGuest);
            setIsAuthenticated(true);

            if (shouldRedirect) {
                navigate('home');
            }

            if (callback) {
                callback();
            }
        } else {
            handleDestroySession();
        }
    };

    const handleDestroySession = () => {
        destroySession();
        logout();
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, guest }}>
            
            {
                !isLoading && isAuthenticated && 
                <>
                    <Header/>
                    <main className='container'>
                        {children}
                        <DismissableMessage guestName={guest?.preferredName ?? ''} message={guest?.welcomeMessage ?? ''} />
                    </main>
                    <Footer/>
                    
                </>
            }

            {
                !isLoading && !isAuthenticated && (
                    <Login onLoginEvent={() => {
                        handleLogin(true, () => {});
                    }}/>
                )
            }
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};