import React from "react";
import './Pill.scss';

interface PillProps {
    name: string;
    size?: string;
    className?: string;
}

const Pill: React.FC<PillProps> = ({ name, size = 'auto', className = '' }) => {
    return (
        <div className={`pill-container ${className}`}  style={{ width: size }}>
            <span className="content">
                {name}
            </span>
        </div>
    );
};

export default Pill;
