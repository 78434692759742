import React from 'react';
import './AttireGuide.scss';

const AttireGuide: React.FC = () => {
  // Array of color hex codes
  const guestColors = ['#CBA680', '#A3876F','#767779','#808C95','#42556C', '#152239'];
  const principalSponsors = ['#42556C',  '#152239'];

  return (
    <div className="attire-guide-container p-1">
        <span className="header-title">ATTIRE GUIDE</span>

        <div className="palette-container mt-1 p-1">
            <div className="">
                <span className="content">
                    We kindly encourage everyone to dress in our color palette for our special day, with a request for semi-formal attire.
                </span>
            </div>
            

            <div className="mt-1">
                <span className="content">
                    Guests
                </span>
            </div>
            
            <div className="palette-box mt-1">
                {guestColors.map((color, index) => (
                    <div 
                    className="color-box"
                    key={index} 
                    style={{ backgroundColor: color }}
                    />
                ))}
            </div>

            <div className="mt-1">
                <span className="content">
                    Principal Sponsors
                </span>
            </div>

            <div className="palette-box mt-1">
                {principalSponsors.map((color, index) => (
                    <div 
                    className="color-box"
                    key={index} 
                    style={{ backgroundColor: color }}
                    />
                ))}
            </div>

            
        </div>
        
    </div>
    
  );
};

export default AttireGuide;
