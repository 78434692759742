import { useEffect, useState } from 'react';
import './Home.scss'

import HomeHeadings, { HomeHeadingsProps } from './components/HomeHeadings';
import ScheduleBox, { ScheduleBoxProps } from './components/ScheduleBox';
import Timeline, { TimelineItemProps } from './components/Timeline';
import WeddingLocation, { WeddingLocatitonProps } from './components/WeddingLocation';
import { WeddingData } from '../../models/WeddingInfoResponse';
import { getWeddingInfo } from '../../services/session';
import AttireGuide from './components/AttireGuide';
import Slider from './components/Slider';
import SaveTheDate from './components/SaveTheDate';

const Home = () => {

    const [weddingInfo, setWeddingInfo] = useState<WeddingData>();

    useEffect(() => {

        const weddingInfo = getWeddingInfo();

        if (weddingInfo) {
            setWeddingInfo(weddingInfo);
        }
    }, []);

    return <>
        <HomeHeadings {...weddingInfo?.propsHeading as HomeHeadingsProps}/>
        <ScheduleBox {...weddingInfo?.propsScheduleBox as ScheduleBoxProps}/>
        <Timeline timelineItems={weddingInfo?.propsTimeline as TimelineItemProps[]}/>
        <Slider/>
        <SaveTheDate/>
        <WeddingLocation {...weddingInfo?.propsWeddingLocation as WeddingLocatitonProps}/>
        <AttireGuide/>
    </>
    
};

export default Home;

