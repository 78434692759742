import react, { useEffect, useState } from 'react';
import './Login.scss';
import logo from './../../../assets/images/logo-small.png';

import { getMe, getWeddingInfo, login } from './../../../services/guestService';
import { GuestLoginRequest } from '../../../models/GuestLoginRequest';
import { GuestResponse } from '../../../models/GuestResponse';
import { isGuestLoggedIn, saveGuest, saveToken, saveWeddingInfo } from '../../../services/session';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../Spinner/Spinner';
import Uploader from '../Uploader/Uploader';

interface LoginComponentProps {
    onLoginEvent?: ((guest: GuestResponse) => void) | null | undefined;
}

const Login: react.FC<LoginComponentProps> = ({onLoginEvent}) => {

    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const id = queryParams.get('id') ?? '';
    const pin = queryParams.get('pin') ?? '';

    const [isLoading, setIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');


    useEffect(() => {
        if (isGuestLoggedIn()) {
            navigate('/home');
        } else {
            setIsLoading(true);

            const timer = setTimeout(() => {
    
                if (!id?.length || !pin?.length) {
                    setIsLoading(false);
                    setErrorMessage('We apologize, but our website is accessible exclusively through the QR code. Kindly scan the QR code provided in your invitation once again. Thank you for your understanding!');
                } else {
                    triggerLogin(id, pin);
                }
                
            }, 1000); 
             
            return () => clearTimeout(timer);
        }
    }, []);

    const triggerLogin = async (id: string, pin: string) => {
        setIsLoading(true);
        const request: GuestLoginRequest = {
            id: id,
            pin: pin
        };
        try {

            const token = (await login(request)).data;
            saveToken(token);

            const guest = (await getMe()).data;
            saveGuest(guest);

            const weddingInfo = (await getWeddingInfo()).data;
            saveWeddingInfo(weddingInfo);
            
            if (onLoginEvent) {
                onLoginEvent(guest);
            }

            setIsLoading(false);

        } catch(ex: any) {

            if (ex?.status && ex?.status === 401) {
                setErrorMessage('Invitation code is invalid.');
            } else {
                setErrorMessage('Oops, something went wrong. Please try again later!');
            }

            setIsLoading(false);
        }
    };

    return <>
        {/* <Uploader/> */}
        <div className="bg-celestial">
            <div className="login-container p-1">
                
                <div className="logo mt-1">
                    <img src={logo} height={380} width={380} alt="our logo" />
                </div>

                <div className="invitation-code p-1 mt-2">
                    {
                        isLoading && (
                            <>
                                <div className="">
                                    <span className="section-title">VALIDATING INVITATION CODE</span>
                                </div>
                                <div className="mt-1">
                                    <Spinner size="30px" />
                                </div>
                                <div className="mt-1">
                                    <span className="content">
                                        Welcome to our wedding website.
                                        Please wait while we validate your pin to access exclusive details about our special day.
                                        We can't wait to celebrate this unforgettable moment with you!
                                    </span>
                                </div>
                            </>
                        )
                    }
                    <span className="content">
                        {errorMessage}
                    </span>
                    
                </div>
            </div>
        </div>
    </>
};

export default Login;