import './HomeHeadings.scss'

export interface HomeHeadingsProps {
    firstPhrase: string;
    secondPhrase: string;
    thirdPhrase: string;
    brideAndGroom: string;
}

const HomeHeadings: React.FC<HomeHeadingsProps> = ({brideAndGroom = '', firstPhrase = '', secondPhrase = '', thirdPhrase}) => {

    return <>
        <div className="home-headings-container pl-1 pr-1">
            <div className="headings">
                <div className="">
                    <span className="section-title">
                        {firstPhrase}<br/>{secondPhrase}<br/>{thirdPhrase}
                    </span>
                </div>
                <div className="mt-2">
                    <span className="header-title">
                        {brideAndGroom}
                    </span>
                </div>
            </div>
            
        </div>
    </>
    
};

export default HomeHeadings;

