import api from './api';
import { GuestLoginRequest } from '../models/GuestLoginRequest';
import { GuestResponse } from '../models/GuestResponse';
import { AxiosResponse } from 'axios';
import { WeddingData } from '../models/WeddingInfoResponse';
import { GuestRegisterRequest } from '../models/GuestRegisterRequest';

const controller = "guest";

const loginUrl = `${controller}/login`;
const getMeUrl = `${controller}/getme`;
const getInfoUrl = `${controller}/wedding-info`;
const registerUrl = `${controller}/register`;

export const login = (req: GuestLoginRequest): Promise<AxiosResponse<string>> => {
    return api.post<string>(loginUrl, req);
};

export const getMe = (): Promise<AxiosResponse<GuestResponse>> => {
    return api.get<GuestResponse>(getMeUrl);
};

export const getWeddingInfo = (): Promise<AxiosResponse<WeddingData>> => {
    return api.get<WeddingData>(getInfoUrl);
};

export const register = (req: GuestRegisterRequest): Promise<AxiosResponse<string>> => {
    return api.post<string>(registerUrl, req);
}