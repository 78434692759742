import './Timeline.scss'
import camera from './../../../assets/images/camera.png';
import church from './../../../assets/images/church.png';
import couple from './../../../assets/images/couple.png';
import music from './../../../assets/images/music.png';
import toast from './../../../assets/images/toast.png';

export interface TimelineItemProps {
    image: string;
    altText: string;
    title: string;
    time: string;
    topDown: boolean;
}

export interface TimelineProps {
    timelineItems: TimelineItemProps[]
}

const Timeline: React.FC<TimelineProps> = ({timelineItems = []}) => {


    const getImageByName = (name: string): string | undefined => {
        const images: { [key: string]: string } = {
            camera: camera,
            church: church,
            couple: couple,
            music: music,
            toast: toast,
        };
        
        return images[name]; 
    };

    return <>
        <div className="timeline-container mt-2 pl-1 pr-1">
            <div className="timeline-row">
                {
                    timelineItems && timelineItems?.map((t, i) => {
                        if (t.topDown) {
                            return (
                                <div key={i} className="timeline-item bl br">
                                    <img className="mb-1" src={getImageByName(t.image)} alt="ceremony" /> <br/>
                                    <span className="content">{t.title}</span> <br/>
                                    <span className="content">{t.time}</span>
                                </div>
                            );
                        } else {
                            return (
                                <div key={i} className="timeline-item bl br topper">
                                    <span className="content">{t.title}</span> <br/>
                                    <span className="content">{t.time}</span> <br/>
                                    <img className="mt-1" src={getImageByName(t.image)} alt="photoshoot" /> 
                                </div>
                            );
                        }
                    })
                }
            </div>
        </div>
    </>
};

export default Timeline;