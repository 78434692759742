import React from 'react';
import './Spinner.scss'; // 

interface SpinnerProps {
  size: string; // Pass size as a prop, for example, "50px", "100px", etc.
}

const Spinner: React.FC<SpinnerProps> = ({ size }) => {
  return (
    <div
      className="spinner"
      style={{ "--size": size } as React.CSSProperties}
    ></div>
  );
};

export default Spinner;