import React from 'react';
import './Slider.scss'; // Assuming your styles are in this file

import img from './../../../assets/images/slider.png'

const Slider = () => {
  return (
    <div className="responsive-image-container">
      <img src={img} alt="Responsive Wedding Photo" className="responsive-image" />
    </div>
  );
};

export default Slider;
