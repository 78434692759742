import React, { useEffect, useState } from 'react';
import './GuestIcon.scss';

interface GuestIconProps {
  currentUserName: string;
  text: string;
  size: number; // Size of the circle in px
}

const GuestIcon: React.FC<GuestIconProps> = ({ currentUserName, text, size }) => {
   // Array of allowed colors
   const colors = ['#171F45', '#141a3b', '#0c0f23', '#B38F46', '#1C1C1C', '#0c0c0c'];
   const [color, setColor] = useState('');

   useEffect(() => {
    setColor(getRandomColor());
   }, []);

   // Function to select a random color from the array
   const getRandomColor = (): string => {
     const randomIndex = Math.floor(Math.random() * colors.length);
     return colors[randomIndex];
   };

  // Extract first letter from the text
  const firstLetter = text.charAt(0).toUpperCase();

  const isBrideOrGroom = (preferredName: string) => {
    return preferredName.toLowerCase() === 'bride' || preferredName.toLowerCase() === 'groom';
  }

  // Inline style for the circle
  const circleStyle = {
    backgroundColor: isBrideOrGroom(text) ? '#fafafa' : color,
    color: isBrideOrGroom(text) ? '#171F45' : '#fafafa',
    width: `${size}px`,
    height: `${size}px`,
    lineHeight: `${size}px`, // Vertically centers the text
    fontSize: `${size / 2}px`, // Adjust font size based on circle size
  };

  const splitName = (name: string) => {
    try {
      if (name?.indexOf(',') !== -1) {
        const pieces = name?.split(',');
        const newName =  `${pieces[0]} + ${pieces.length - 1}`

        return newName;
      }
  
      return name;
    } catch(e) {
      return name;
    }
  };

  return (
    <div className="guest-icon-container" title={currentUserName === text ? `(You) ${splitName(text)}` : splitName(text)}>
      <div className="guest-icon-circle" style={circleStyle}>
        {firstLetter}
      </div>
    </div>
  );
};

export default GuestIcon;
