import react from 'react';
import './GiftRegistry.scss';

const GiftRegistry = () => {
    return <>
        <div className="gift-registry-container pl-1 pr-1">
            <div className="rsvp-headings-container">
                <div className="">
                    <span className="content">ANGELICA & KYLE</span>
                </div>
                <div className="mt-1">
                    <span className="header-title">ABOUT GIFTS</span> 
                </div>
            </div>
            <div className="gift-box mt-1">
                <span className="section-title">
                    Your presence on our special day is the most meaningful gift we could ever receive. However, if you feel inclined to give a little extra, a monetary contribution would be warmly welcomed and appreciated.
                </span>
            </div>
        </div>
    </>
};

export default GiftRegistry;