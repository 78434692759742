import './SaveTheDate.scss';

import img1 from './../../../assets/images/img1.JPG';
import img2 from './../../../assets/images/img2.JPG';
import img3 from './../../../assets/images/img3.JPG';

const SaveTheDate = () => {

    return <>
        <div className="topper mt-2">
            <span className="header-title">SAVE THE DATE</span>
        </div>
        <div className="save-the-date-container pl-1 pr-1">
            <div className="image-stack">
                <div className="image-wrapper image-1">
                    <img src={img1} alt="image 1" className="image" />
                </div>
                <div className="image-wrapper image-2">
                    <img src={img3} alt="image 3" className="image" />
                </div>
                <div className="image-wrapper image-3">
                    <img src={img2} alt="image 2" className="image" />
                </div>
            </div>
            <div className="video">
                <span className="content">VIDEO COMING SOON!</span>
            </div>
          
        </div>
    </>;
};

export default SaveTheDate;