import './Footer.scss';

import footerbg0 from './../../../assets/images/footer.png'
import footerbg1 from './../../../assets/images/footer-celestial.png';
import footerbg2 from './../../../assets/images/footer-cover.png';

const Footer = () => {

    return <>
        <div className="mt-2 footer-container">
            <img className="footer-image" src={footerbg0} alt="footer background" />
            <img className="footer-image" src={footerbg1} alt="footer background" />
            <img className="footer-image" src={footerbg2} alt="footer background" />
            <div className='footer-image text-container'>
                <span className="sub-header-title">
                    CAPTURE US
                </span>
                <br/>
                <span className="content">
                    HELP US CAPTURE OUR SPECIAL MOMENT
                </span>
                <br/>
                <span className="content">
                    #MaeForeverSiHarley
                </span>
            </div>
        </div>
        
    </>
};

export default Footer;