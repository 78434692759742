import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import './Header.scss';

import logo from './../../../assets/images/logo-small.png';
import bg0 from './../../../assets/images/headerbg0.JPG';
import bg1 from './../../../assets/images/headerbg1.JPG';
import bg2 from './../../../assets/images/headerbg2.JPG';
import bg3 from './../../../assets/images/headerbg3.JPG';
import cover0 from  './../../../assets/images/header-darkify.png';
import cover1 from './../../../assets/images/header-cover-gold.png';

import Visitors from "../Visitors/Visitors";
import DismissableMessage from "../DismissableMessage/DismissableMessage";
import { getGuest } from "../../../services/session";
import { GuestResponse } from "../../../models/GuestResponse";

const Header = () => {

    const [guest, setGuest] = useState<GuestResponse>();
    const location = useLocation();

    useEffect(() => {
        const storedGuest = getGuest();

        if (storedGuest) {
            setGuest(storedGuest);
        }
    }, []);

    const isActive = (path: string, identifiers: string[]): boolean => {
        let result: boolean = false;

        if (!path?.length) {
            return result;
        }
        
        const pieces = path.split('/');
        const lastPiece = pieces[pieces?.length - 1];

        identifiers.forEach((i: string) => {
            if (lastPiece === i || lastPiece.includes(i)) {
                result = true;
            }
        });

        return result
    }

    const getClassName = (path: string, identifiers: string[]): string => {
        const  isCurrentPage = isActive(path, identifiers);
        return isCurrentPage ? 'nav-item nav-font active' : 'nav-item nav-font';
    };

    const bgs = [bg0, bg1, bg2, bg3];

    // Function to select a random color from the array
    const getRandomImage = (): string => {
      const randomIndex = Math.floor(Math.random() * bgs.length);
      return bgs[randomIndex];
    };
 
    return <>

        <div className="nav mb-2">
            <div className="nav-logo">
                <img src={logo} height={300} width={300} alt="our logo" />
            </div>

            <div className={`nav-items show`}> 
                <span className={getClassName(location?.pathname, ['/','home'])}><Link to={`home`}>WELCOME</Link></span>
                <span className={getClassName(location?.pathname, ['entourage'])}><Link to={`entourage`}>ENTOURAGE</Link></span>
                <span className={getClassName(location?.pathname, ['rsvp'])}><Link to={`rsvp`}>RSVP</Link></span>
                {/* <span className={getClassName(location?.pathname, ['people'])}><Link to={`people`}>PEOPLE</Link></span> */}
                <span className={getClassName(location?.pathname, ['gift-registry'])}><Link to={`gift-registry`}>ABOUT GIFTS</Link></span>
            </div>

            <img className="header-image" src={getRandomImage()} alt="footer background" />
            <img className="header-image" src={cover0} alt="footer background" />
            <img className="header-image" src={cover1} alt="footer background" />
            
            <Visitors />

        </div>

        
        
    </>
};

export default Header;