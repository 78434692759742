import './EntourageContent.scss';
import coupleKiss from './../../../assets/images/couple-kiss.png';
import Pill from '../../shared/Pill/Pill';
import { Entourage } from '../../../models/WeddingInfoResponse';

export interface EntourageContentProps {
    entourage: Entourage;
}

const EntourageContent: React.FC<EntourageContentProps> = ({entourage}) => {

    return <>
        <div className="entourage-content-container mt-1 p-1">
            <div className="entourage-row">
                <div className="entourage-row-item pt-2">
                    <span className="section-title">
                        PARENTS OF THE BRIDE
                    </span>
                    {
                        entourage.bridesParents.map((i: string, k) => {
                            return <Pill key={i+k} className="mt-1" name={i}/>
                        })
                    }
                </div>
                <div className="entourage-row-item pl-2 pr-2">
                    <img className="mb-1" src={coupleKiss} alt="ceremony" /> <br/>
                </div>
                <div className="entourage-row-item pt-2">
                    <span className="section-title">
                    PARENTS OF THE GROOM
                    </span>
                    {
                        entourage.groomsParents.map((i: string, k) => {
                            return <Pill key={i+k} className="mt-1" name={i}/>
                        })
                    }
                </div>
            </div>

            <div className="entourage-row mt-1">
                <span className="section-title">PRINCIPAL SPONSORS</span>
            </div>
            <div className="entourage-row">

                <div className="entourage-row-item pl-2 pr-2">
                    {
                        entourage.principalSponsors.slice(0, Math.ceil(entourage.principalSponsors.length / 2))
                        .map((i: string, index: number) => (
                            <Pill key={index} className="mt-1" name={i} />
                        ))
                    }
                </div>

                <div className="entourage-row-item pl-2 pr-2">
                   
                    {
                        entourage.principalSponsors.slice(Math.ceil(entourage.principalSponsors.length / 2))
                        .map((i: string, index: number) => (
                            <Pill key={index} className="mt-1" name={i} />
                        ))
                    }
                </div>
            </div>

            <div className="entourage-row">
                <div className="entourage-row-item pl-2 pr-2  mt-1">
                    <span className="section-title">BEST MAN</span>
                    <Pill className="mt-1" name={entourage.bestMan}/>
                </div>
                <div className="entourage-row-item pl-2 pr-2  mt-1">
                    <span className="section-title">MAID OF HONOR</span>
                    <Pill className="mt-1" name={entourage.maidOfHonor}/>
                </div>
            </div>

            <div className="entourage-row mt-1">
                <div className="entourage-row-item">
                    <span className="section-title">SECONDARY SPONSORS</span>
                </div>
            </div>

            <div className="entourage-row">
                <div className="entourage-row-item pl-1 pr-1 mt-1">
                    <span className="sub-section-title">TO LIGHT OUR PATH</span>
                    <Pill className="mt-1" name={entourage.secondarySponsors[0].male}/>
                    <span className="section-title">&</span>
                    <Pill name={entourage.secondarySponsors[0].female}/>
            
                </div>
                <div className="entourage-row-item pl-1 pr-1 mt-1">
                    <span className="sub-section-title">TO CLOTHE AS ONE</span>
                    <Pill className="mt-1" name={entourage.secondarySponsors[1].male}/>
                    <span className="section-title">&</span>
                    <Pill name={entourage.secondarySponsors[1].female}/>
                </div>
                <div className="entourage-row-item pl-1 pr-1 mt-1">
                    <span className="sub-section-title">TO BIND US TOGETHER</span>
                    <Pill className="mt-1" name={entourage.secondarySponsors[2].male}/>
                    <span className="section-title">&</span>
                    <Pill name={entourage.secondarySponsors[2].female}/>
                </div>
            </div>

            <div className="entourage-row">
                <div className="entourage-row-item pl-2 pr-2 mt-1">
                    <span className="section-title">GROOMSMEN</span>
                    {
                        entourage.groomsMen.map((i: string, k) => {
                            return <Pill key={i+k} className="mt-1" name={i}/>
                        })
                    }
                </div>
                <div className="entourage-row-item fillers-space">
                    <span className="section-title">&nbsp;</span>
                    <div className="and-fillers mt-1">
                        <span className="section-title">&</span>
                    </div>
                    <div className="and-fillers mt-1">
                        <span className="section-title">&</span>
                    </div>
                    <div className="and-fillers mt-1">
                        <span className="section-title">&</span>
                    </div>
                </div>
                <div className="entourage-row-item pl-2 pr-2 mt-1">
                    <span className="section-title">BRIDESMAID</span>
                    {
                        entourage.bridesMaid.map((i: string, k) => {
                            return <Pill key={i+k} className="mt-1" name={i}/>
                        })
                    }
                </div>
            </div>

            <div className="entourage-row">
                <div className="entourage-row-item pl-2 pr-2 mt-1">
                    <span className="section-title">
                        RING BEARER
                    </span>
                    <Pill className="mt-1" name={entourage.ringBearer}/>
                </div>
                <div className="entourage-row-item pl-2 pr-2 mt-1">
                    <span className="section-title">
                        BIBLE BEARER
                    </span>
                    <Pill className="mt-1" name={entourage.bibleBearer}/>
                </div>
                <div className="entourage-row-item pl-2 pr-2 mt-1">
                    <span className="section-title">
                        COIN BEARER
                    </span>
                    <Pill className="mt-1" name={entourage.coinBearer}/>
                </div>
            </div>

            <div className="entourage-row mt-1">
                <div className="entourage-row-item pl-2 pr-2">
                    <span className="section-title">
                        FLOWER GIRLS
                    </span>
                </div>
            </div>

            <div className="entourage-row">
                {
                    entourage.flowerGirls.map((f: string, k) => {
                        return (
                            <div key={f+k} className="entourage-row-item mt-1 pl-2 pr-2">
                                <Pill name={f}/>
                            </div>
                        )
                    })
                }
            </div>


            
        </div>
    </>
};

export default EntourageContent;